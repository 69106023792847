
.read-root {
    background-color: #ffffff;
    margin: 2vh 0;
    width: 100%;
    padding: 20px 30px 25px 30px;
    filter: opacity(95%);
}

.read-content {
    margin-bottom: 30px;
}

.read-content-title {
    border-bottom: 1px solid #e8e9e7;
}

.read-content-title h4 {
    font-size: x-large;
}

.read-article-message a {
    color: #3E8BC7;
}

.read-article-message small:nth-child(n+2){
    margin-left: 10px;
}

.read-mobel-warp {
    display: none;
}

.read-content-time {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    top: 20px;
    right: -4%;
    line-height: 35px;
    width: 20%;
    min-width: 80px;
    white-space: nowrap;
}

.read-content-time .day {
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    color: #6bc30d;
    position: relative;
    width: 100%;
    /* top: 2px; */
}

.read-content-time .month, .read-content-time .year {
    font-size: 16px;
    font-weight: 500;
    color: #989997;
}

.read-article-body {
    margin: 10px 0;
}

.read-article-body p {
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 400;
    line-height: 26px;
    margin: 0 0 16px;
    overflow: hidden;
    overflow-x: auto;
}

.read-article-body pre ol {
    min-width: 950px;
    overflow-y: hidden;
    overflow-x: auto;
    /* 下面这行不能加，加上之后代码不会首行缩进 */
    /* white-space: nowrap; */
}

.read-article-body img{
    width: 60%;
}

.read-article-body pre{
    background-color: #fafafa;
    padding: 10px;
    border-radius: 5px;
}

.read-article-body table {
    border-collapse: collapse;
    display: table;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
}

.read-article-body table tr{
    border: 0;
    border-top: 1px solid #ddd;
    background-color: #fff;
}

.read-article-body table tr th{
    font-weight: 700;
    background-color: #eff3f5;
    border: 1px solid #ddd;
    padding: 8px;
}

.read-article-body table tr td{
    font-size: 14px;
    color: #4f4f4f;
    line-height: 22px;
    border: 1px solid #ddd;
    padding: 8px;
    word-break: normal!important;
    vertical-align: middle;
}

.read-article-body table tr:nth-child(2n) {
    background-color: #f7f7f7;
}

.markdown-body p{
    font-size: larger;
}

.read-about {
    clear: both;
    background-color: #F5F5F5;
    padding: 15px 20px;
    line-height: 22px;
    text-shadow: 0 1px 0 rgb(255 255 255 / 80%);
    font-size: 12px;
    color: #787977;
    overflow: auto;
    
}

.read-about-overflow {
    min-width: 530px;
    overflow-y: hidden;
    overflow-x: auto;
}

.read-about p{
    margin-bottom: 10px;
    font-size: larger;
}

.read-about p:nth-child(-n+1){
    margin-top: 10px;
}

.read-extend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    clear: both;
    background-color: #fff;
    margin-top: 20px;
    padding: 15px 20px;
    text-shadow: 0 1px 0 rgb(255 255 255 / 80%);
    font-size: 12px;
    border-top: solid 1px #e8e9e7;
    border-bottom: solid 1px #e8e9e7;
    /* color: #787977; */
}

.read-last, .read-next {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 10vh;
    width: 15vw;
    min-width: 100px;
    background-color: #f6f6f6;
}

.read-last {
    justify-content: flex-start;
    margin-left: 1vw;
    cursor: pointer;
}

.read-last:hover p{
    color: #6bc30d;
    transition: all 0.3s;
}

.read-last-article {
    margin-left: 10px;
}

.read-next-article {
    margin-right: 10px;
}

.read-last-article p, .read-next-article p{
    font-size: large;
    font-weight: 500;
    margin-bottom: 10px;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 2;
    -moz-box-orient: vertical;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;  
    transition: all 0.3s;  
}

.read-split {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: dashed 2px #F1F2F0;
}

.read-next {
    justify-content: flex-end;
    margin-right: 1vw;
    cursor: pointer;
}

.read-last:hover, .read-next:hover {
    background-color: #f1f1f1;
}

.read-next:hover p{
    color: #6bc30d;
    transition: all 0.3s;
}

.read-article-tags a {
    display: inline-block;
    font-size: 12px;
    padding: 2px 5px;
    background-color: #dedfde;
    color: #575757;
    margin-left: 5px;
    text-align: center;
    /* margin: 2px; */
    /* text-decoration: none; */
    -webkit-transition: all .2s;
    transition: all .2s;
    font-style: normal;
}

.read-comment-head {
    margin: 10px 0;
    border-bottom: solid 1px #e8e9e7;
}

.read-comment-head h3 {
    font-size: x-large;
}

.read-comment-head button {
    position: relative;
    top: -10px;
}

.remark-message {
    position: relative;
    top: -10px;
    left: 10px; 
}

.read-comment-item {
    padding: 15px 0;
    border-bottom: 1px dotted grey;
    background-color: #ffffff;
    animation: commentItem 1s both;
}

.read-comment-list {
    padding: 0;
}

.read-comment-body {
    margin: 0 10px;
    /* overflow: auto; */
}

.read-comment-body .read-comment-content {
    /* max-width: 900px;
    min-width: 600px;
    overflow-y: hidden;
    overflow-x: auto; */
}

.read-comment-parent, .read-comment-child {
    display: flex;
}

.read-comment-child {
    margin: 10px 0 0 60px;
}

.read-user-name {
    color: #01AAED;
    display: inline;
}

.read-comment-content {
    width: calc(1280px - 120px);
    /* word-wrap: break-word; */
    /* 长单词自动换行 */
    word-break: break-all;
}

.read-comment-child .read-comment-content {
    width: calc(1280px - 180px);
    margin-bottom: 0;
}

.read-comment-reply {
    margin-left: 5px;
    color: #009688;
    cursor: pointer;
}


@keyframes commentItem
{
    0% {
        opacity: 0;
        transform: scale3d(.3,.3,.3);
    }
    50% {
        opacity: 1;
    }
}

/* 屏幕自适应 */
@media (min-width: 0) and (max-width: 1024px) {

    .read-root {
        width: 95vw;
    }

    .markdown-body p{
        font-size: small;
    }

    .read-mobel-warp {
        display: inline-block;
    }

    .read-mobel-category {
        margin-left: 0 !important;
    }

    .read-content-time {
        display: none;
    }

    .read-article-tags {
        display: none;
    }

    .read-last-article p, .read-next-article p{
        font-size: small;
        font-weight: 300;
        -webkit-line-clamp: 3;
        -moz-line-clamp: 3;
    }
}

@media (min-width: 0px) and (max-width: 1366px) {
    .read-comment-content {
        width: calc(80vw - 120px);
    }

    .read-comment-child .read-comment-content {
        width: calc(80vw - 170px);
    }
}