.base-setting {
    width: 500px;
    height: 500px;
    border: 20px solid darkgreen;
    /* display: -webkit-flex; Safari */
    /* display: flex; */
    /* flex-direction: row-reverse; */
    /* justify-content: center; */
    /* align-items: center; */
}

.box1 {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: red;
}

.box2 {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: blue;
}
