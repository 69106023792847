
/* 定义字体 */
@font-face{
    font-family: STXingkai;
    src: url('../../../font/STXingkai.ttf');
}

.message-root {
    margin-top: 2vh;
    width: 100%;
    filter: opacity(95%);
}

.message-head {
    width: 100%;
    /* height: 38vh; */
    background-color: #ffffff;
    padding: 20px 0;
    border-radius: 10px;
}

.message-head-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
}

.message-head-title h1 {
    margin: 0;
    font-size: 400%;
    width: 100%;
    font-family: 'STXingkai';
}

.message-head-title p {
    font-size: 200%;
    font-family: 'STXingkai';
}

.message-head-content button {
    margin: 0 10px;
}

.message-body {
    width: 100%;
    margin: 20px 0;
}

.message-list {
    padding: 0;
}

.message-list .message-item:first-child {
    border-radius: 10px 10px 0 0;
    
}

.message-list .message-item:last-child {
    border-radius: 0 0 10px 10px;
}

.message-item {
    padding: 15px;
    border-bottom: 1px dotted grey;
    background-color: #ffffff;
    animation: messageItem 1s both;
}

.comment-body {
    margin: 0 10px;
}

.comment-parent, .comment-child {
    display: flex;
}

.comment-child {
    margin: 10px 0 0 60px;
}

.comment-user-name {
    color: #01AAED;
    display: inline;
}

.comment-content {
    width: calc(1280px - 100px);
    /* word-wrap: break-word; */
    /* 长单词自动换行 */
    word-break: break-all;
    margin-top: 0;
    margin-bottom: 1em;
    color: #222226;
    font-size: 14px;
    /* font-weight: bold */
}

.comment-child .comment-content {
    width: calc(1280px - 150px);
    margin-bottom: 0;
}

.comment-reply {
    margin-left: 5px;
    color: #009688;
    cursor: pointer;
}

.load-more {
    display: flex;
    justify-content: center;
}

.load-more-body {
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: 0.5; */
    border-radius: 10px;
    height: 4vh;
    width: 25vw;
    cursor: pointer;
    /* background-color: #6BC30D; */
    background:rgba(255, 255, 255, 0.2);
    transition: all 0.5s;
    
}

.load-more-unvisiable {
    display: none;
}

.load-more-body p {
    margin: 0;
}

.load-more-body:hover {
    background:rgba(255, 255, 255, 0.5);
    /* background:rgba(0, 150, 136, 1) */
    transition: all 0.5s;
}

.message-museScore {
    height: 80vh;
}

.emoji-editor {
    margin: 10px 0;
}

@keyframes messageItem
{
    0% {
        opacity: 0;
        transform: scale3d(.3,.3,.3);
    }
    50% {
        opacity: 1;
    }
}


/* 屏幕自适应 */
@media (min-width: 0px) and (max-width: 1366px) {
    .comment-content {
        width: calc(80vw - 100px);
    }

    .comment-child .comment-content {
        width: calc(80vw - 150px);
    }
}

@media (min-width: 0px) and (max-width: 420px) {
    .message-head-title h1{
        font-size: 300%;
    }

    .message-head-title p{
        font-size: 150%;
    }
}