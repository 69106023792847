/* 定义字体 */
@font-face {
    font-family: STXingkai;
    src: url('../../font/STXingkai.ttf');
}

.index-root {
    /* background-image: url("../../image/bg.png"); */
    /* background-image: url("../../image/indexBg/bg.png"); */
    /* background-repeat: repeat; */
    /* background-position: center; */
    /* background-size: cover; */
    background-color: #ffffff;
    display: -webkit-flex;
    /* Safari */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.index-root img {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    object-fit: cover;
}

.index-bgimg {
    position: relative;
    transform: scale(1.2);
}

.index-mask {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: #374140;
    z-index: 30;

}

.index-mask-out {
    z-index: 0;
    filter: opacity(0);
    animation: 0.8s maskAnimation ease-in;
}

.index-site {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: -20vh;
    font-size: x-large;
    z-index: 20;
    /* transform: scale(0); */
    animation: 2s startAnimation ease-out;
}

.site-continer{
    display: flex;
}

.index-site h1 {
    display: inline;
    width: auto;
    font-size: 4rem;
    margin-bottom: 0;
    transform: scale(1, 1);
    transition: all 0.6s;
}

.index-site h1:hover {
    /* font-size: 5rem; */
    transform: scale(1.1, 1.1);
    transition: all 0.6s;
    cursor: pointer;
}

/* .index-site h1::before {
    content: ">";
    width: 10px;
    height: 10px;
    animation: 10s siteBeforAfter linear;
} */

/* .index-site h1::after {
    content: "<";
    width: 10px;
    height: 10px;
    animation: 10s siteBeforAfter linear;
} */

.index-slogan,
.index-site h1 {
    width: 100%;
    text-align: center;
}

.index-site h1,
.index-slogan p {
    color: #ffffff;
    font-family: 'STXingkai';
}

.index-button {
    background-color: #00BFFF;
    border: #00BFFF;
    height: 30px;
    margin-top: 1%;
    border-radius: 5px;
    /* filter: opacity(0.8); */
}

.index-button:hover {
    /* background-color: #0080FF; */
    background-color: #01A9DB;
    transform: scale(1.1, 1.1);
    transition: all 0.3s;
    /* filter: blur(2px); */
    /* animation: 0.8s fontAnimation cubic-bezier(0.42,0,0.58,1); */
}

.index-button:active {
    /* background-color: #0080FF; */
    background-color: #0395c2;
    transform: scale(1, 1);
    transition: all 0.1s;
    /* filter: blur(2px); */
    /* animation: 0.8s fontAnimation cubic-bezier(0.42,0,0.58,1); */
}

.index-button::after {
    background-color: #00BFFF;
}

@keyframes siteBeforAfter {
    30% {
        filter: opacity(0);
    }

    50% {
        filter: opacity(1);
    }

    70% {
        filter: opacity(0);
    }
}

@keyframes maskAnimation {
    0% {
        filter: opacity(1);
    }

    100% {
        filter: opacity(0);
    }
}

@keyframes startAnimation {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fontAnimation {
    0% {
        transform: scale3d(1, 1, 1);
    }

    15% {
        transform: scale3d(1.3, .7, 1);
    }

    30% {
        transform: scale3d(0.75, 1.25, 1);
    }

    45% {
        transform: scale3d(1.2, 0.8, 1);
    }

    60% {
        transform: scale3d(0.85, 1.15, 1);
    }

    75% {
        transform: scale3d(1.1, .9, 1);
    }

    90% {
        transform: scale3d(0.95, 1.05, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

@media (min-width: 2560px) and (max-width: 7680px) {
    .index-site h1 {
        font-size: 5rem;
    }

    .index-slogan p {
        font-size: 1.5rem;
    }
}