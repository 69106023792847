
.about-root {
    width: 100%;
    margin: 2vh 0;
    background-color: #ffffff;
    filter: opacity(95%);
    animation: about-ami 1s both;
    border-radius: 10px;
}

.about-root h1{
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    border-left: .25rem solid #6bc30d;
}



.about-website, .about-me, .about-updata-log, .about-notes {
    padding: 20px;
}

.about-root p {
    margin: 1.5rem 0;
}

.about-root ul {
    padding: 0;
}

.about-root li {
    margin: 8px 0;
}

.about-root code {
    background: rgba(27,31,35,.05);
    padding: .2rem .4rem;
    color: #bd4147;
    border-radius: 4px;
    font-family: Menlo,Monaco,Consolas,Courier New,monospace;
    transition: all 1s;
}

.about-me a {
    color: #0366d6;
}

.updata-list {
    height: 35vh;
    overflow: auto;
}

::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

.updata-item {
    position: relative;
    padding-left: 20px;
}

.updata-item::before {
    content: '';
    position: absolute;
    left: 27px;
    top: 20px;
    z-index: 0;
    width: 1px;
    height: 115%;
}

.updata-item:before, hr {
    background-color: #e6e6e6;
}

.left-circle {
    position: absolute;
    top: 5px;
    width: 15px;
    height: 15px;
    background: #ffffff;
    border-radius: 50%;
    border: 2px solid #5FB878;
}

.updata-content {
    padding-left: 25px;
}

.updata-content p {
    padding-left: 10px;
}

.about-notes ul{
    padding-left: 18px;
}

@keyframes about-ami
{
    0% {
        opacity: 0;
        transform: scale3d(.3,.3,.3);
    }
    50% {
        opacity: 0.95;
    }
}