.xbclock-root {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.xbclock-content {
    display: flex;
    width: 100%;
    /* justify-content: center;
    align-items: center; */
    flex-wrap: nowrap;
    /* margin-bottom: 10px; */
    margin: 10px 32%;
}

.xbclock-content h3 {
    margin-right: 20px;
    margin-bottom: 0;
}

.xbclock-content button {
    margin: 0 10px;
}

.xbclock-content input {
    width: 165px;
}

.xbclock-root .ant-slider {
    width: 150px;
}

.xbclock-brightness-value {
    margin: 0 0 2px 10px;
}

.sketch-picker {
    position: absolute;
    top: -180px;
}

.xbclock-icon {
    margin: 0 10px 0 2px;
    cursor: pointer;
}

.xbclock-hardwareId span {
    margin: 2px;
}