.left-nav {
    min-height: 100vh;
    background-color: #2F3447;
    min-width: 80px;
    /* height: 100%;
    box-sizing: border-box; */
    
}
.siteLogo {
    display: flex;
    justify-content:center;
    align-items: center ;
    height: auto;
    color: white;
}
.siteLogo h1 {
    font-size: x-large;
    color: white;
    margin-left: 5px;
    margin-top: 15px;
}

.content {
    background-color: #F7F7F7;
}

.content-head {
    height: calc(5vh - 8px);
    background-color: #ffff;
}

.content-body {
    height: 95vh;
}

.body {
    background-color: #F7F7F7;
}

/* .content-body {
    background-color: #F7F7F7;
} */
/* 
.control-title {
    
   
} */



