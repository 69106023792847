/* .add-link {
    border: solid 1px aqua;
    box-shadow: brown;
    background-color: #fff;
    width: 30%;
    text-align: center;
    margin: 30px auto;
    padding: 30px;
}

.add-link button{
    margin:10px 10%;
} */


#add-link-btn{
    margin: 2em 2em 1em 0;
    border-radius: 5px;
    color: #ffffff;
    background-color: #1DA57A;
    float: right;
}
