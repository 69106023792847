
body {
    background-image: url("https://xbblog.oss-cn-shanghai.aliyuncs.com/image/home_bg.jpg");
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
    background-color: #ffffff;
    background-attachment: fixed;
}

/* 隐藏滚动条 */
/* ::-webkit-scrollbar {
    display: none; }
*/
   

.home-root {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100%;
    /* overflow: hidden; */
}

.home-header {
    position: fixed;
    top: 0;
    width: 100%;
    filter: opacity(95%);
    height: 6vh;
    z-index: 100;
    background-color: rgba(255, 255, 255, 1);
}

.home-body {
    /* margin-top: 6vh; */
    width: 80vw;
    display: flex;
    justify-content: center;
    margin-top: 6vh;
    /* filter: opacity(90%); */
    /* align-items: flex-start; */
}


.home-footer {
    width: 100%;
    /* height: 8vh; */
}

.home-mask {
    position: fixed;
    z-index: 110;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.home-nav {
    position: fixed;
    z-index: 120;
    /* left: 0vw; */
    top: auto;
    width: 40vw;
    height: 100%;
    background-color: #ffffff;
    transition: left ease-out 0.3s;
}
	
ul,li {
    list-style: none;
}
			
a {
    text-decoration: none;
}
			
.nav a {
    display: block;
    color: #eee;
    font-size: 2rem;
    padding: 1rem 0;
    text-align: center;
    border-bottom: 1px solid #888;
}

/* 屏幕自适应 */
@media (min-width: 1366px) and (max-width: 4096px) {
    .home-body {
        width: 1280px;
    }
}

@media (min-width: 0px) and (max-width: 1024px) {
    #live2d-container {
        display: none;
    }
}