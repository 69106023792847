
.lcrecord-root {
    max-height: 30vh;
    overflow: auto;
}

.lcrecord-secondTag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 8px 0;
}

.lcrecord-secondTag h4 {
    margin: 0;
    width: 20%;
}

.lcrecord-secondTag-right {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
}

.lcrecord-subjectNum {
    width: 40px;
    height: 25px;
    margin: 5px 10px;
    border-radius: 15%;
    background-color: #F2F4F5;
    text-align: center;
    cursor: pointer;
}

.lcrecord-head {
    display: flex;
    justify-content: flex-end;
}

.lcrecordno-head {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.lcrecordno-head h4 {
    margin-bottom: 30px;
}

.lcrecord-subject-demo{
    width: 55px;
    height: 25px;
    border-radius: 5%;
    background-color: #F2F4F5;
    text-align: center;
    margin: 0 6px;
}

.lcrecord-subjectNum-state1 {
    color: white;
    background-color: yellowgreen;
    transition: all 0.3s;
}

.lcrecord-subjectNum-state2 {
    color: white;
    background-color: orange;
    transition: all 0.3s;
}
