
.home-head-root {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.home-head-body {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    height: 100%;
    /* background-color: green; */
}

.home-head-logo {
    width: 10vw;
}

.home-head-logo img {
    /* height: 6vh; */
    width: auto;  
    height: auto;  
    max-width: 100%;  
    max-height: 100%; 
}

.home-head-logoModel img {
    /* height: 6vh; */
    width: 15vw;  
    height: 6vh; 
    min-width: 180px;
}

.home-head-menudiv {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: flex-end;
    width: 45vw;
}

.home-head-menudiv a {
    max-width: 100px;
}

.home-head-menu {
    display: -webkit-flex; /* Safari */
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    height: 6vh;
    width: 5vw;
    position: relative;
}

.home-head-active {
    display: -webkit-flex; /* Safari */
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    height: 6vh;
    width: 5vw;
    position: relative;
}

.home-head-menu:hover {
    cursor: pointer;
}

.home-head-active {
    border-bottom: 1px solid #6bc30d;
}

.home-head-active {
    color: #6bc30d;
}

/* 菜单栏底部动画 */
.home-head-menu::before, .home-head-menu::after {
    content: "";
    width: 0;
    height: 2px; /*下划线高度*/
    background: #6bc30d; /*下划线颜色*/
    position: absolute;
    top: 98%;
    left: 50%;
    transition: all .3s ;
}

.home-head-menu:hover:before {
    left: 1%; 
    width: 50%;
 }

 .home-head-menu:hover::after {
    left: 50%; 
    width: 50%;
 }

.home-head-menu {
    font-size: small;
    color: #000000;
}

.home-head-menu:hover {
    color:  #6bc30d;
    transition: all .3s;
}

.home-head-user {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: flex-end;
    width: 10vw;
    transition: all 0.2s;
}

.home-head-user svg:hover {
    transition: all 0.2s;
    filter: opacity(0.6);
}

.home-head-user span {
    cursor: pointer;
    transform:scale(1);
    transition: all 0.3s;
}

.home-head-user span:hover {
    transform:scale(1.2);
    transition: all 0.3s;
}

.home-head-menuModel {
    display: none;
}

.home-head-logoModel {
    display: none;
}


/* 屏幕自适应 */
@media (min-width: 0px) and (max-width: 1024px) {

    .home-head-menudiv {
        display: none;
    }

    .home-head-logo {
        display: none;
    }

    .home-head-menuModel {
        display: block;
    }

    .home-head-logoModel {
        display: block;
        justify-content: center;
        /* padding-right: 20px; */
    }

    .home-head-user {
        width: auto;
    }
}

@media (min-width: 1366px) and (max-width: 4096px) {
    .home-head-body {
        width: 1280px;
    }


    .home-head-menu, .home-head-active {
        font-size: 15px;
        transition: all 0.3s;
    }
}