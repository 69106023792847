.article-form {
    width: 100%;
    height: 90vh;
    padding: 2% 10% !important;
    overflow:visible;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;   
    justify-content: center; 
}

.bg-addarticle-title, .bg-addarticle-category {
    flex: 0 0 50%;
}

.bg-addarticle-title input {
    width: 50%;
    min-width: 200px;
}

.bg-addarticle-category div{
    width: 50%;
    min-width: 200px;
}

.bg-addarticle-author, .bg-addarticle-tags, .bg-addarticle-cover {
    flex: 0 0 100%;
}

.bg-addarticle-author input {
    width: 20%;
    min-width: 200px;
    /* width: 50%; */
}

.bg-addarticle-tags input {
    width: 20%;
    min-width: 200px;
    /* width: 50%; */
}

.bg-addarticle-cover {

}

.bg-addarticle-content {
    /* flex: 0 0 100%; */
    height: 80%;
    width: 120%;
    margin: 0 -10% !important;
}

.bg-addarticle-submitBT button {
    width: 200%;
    margin-left: -50%;
}