
.state-root {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
    transition: all 0.5s;
}

.state-pole {
    width: 5px;
    height: 20vh;
    margin: 0 40vw;
    background-color:#ffffff;
}

.state-pole::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(
45deg
);
    width: 20px;
    height: 20px;
    z-index: 2;
    background: #ffffff;
}

.state-body {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 55px;
    top: 80px;
    width: 400px;
    /* height: 140px; */
    background-color:#ffffff;
    border-radius: 5px;
    transition: all 0.5s;
    transform: scale(0);
}

.state-body-visible {
    transform: scale(1);
    animation: 0.4s stateAnimation cubic-bezier(0.42,0,0.58,1);
}

.state-body time {
    position: absolute;
    background: #f5af10;
    width: 100px;
    height: 30px;
    left: 20px;
    top: -15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
}

.state-pole:nth-child(even) .state-body {
    left: -450px;
}

.state-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    /* height: 75%;
    padding: 20px; */
    margin: 20px;
    min-height: 90px;
    background-color: #ffffff;
    box-shadow: 4px 13px 30px 1px rgb(1 0 56 / 10%);
    border-radius: 5px;
}

.state-content p {
    margin: 15px;
}

.state-museScore {
    height: 100vh;
}

.react-emoji-show {
    max-height: 70px;
    overflow: auto;
}

/* state弹出动画 */
@keyframes stateAnimation
{
    0% { opacity: 0; transform: scale(0); }
    80% { transform: scale(1.1); }
    90% { transform: scale(0.95); }
    100% { opacity: 1; transform: scale(1); }
}

/* 屏幕自适应 */
@media (min-width: 666px) and (max-width: 1024px) {
    .state-body {
        width: 250px;
        /* height: 160px; */
        top: 90px;
        transition: all 0.5s;
    }

    .state-pole:nth-child(even) .state-body {
        left: -300px;
    }
}

@media (min-width: 0px) and (max-width: 666px) {
    .state-body {
        left: 55px;
        top: 70px;
        width: 70vw;
        height: 15vh;
        transition: all 0.5s;
    }

    .state-pole:nth-child(even) .state-body {
        left: 55px;
    }

    .state-root {
        left: -170px;
        transition: all 0.5s;
    }
}
