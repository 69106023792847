.bg-home-root {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
}

.bg-home-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 60vw;
}

.bg-home-right {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 20vw;
}

.bg-home-message {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 2%;
    margin-left: 2%;
}

.bg-home-card {
    width: 20%;
    margin: 0 30px;
}

.bg-home-content {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 2%;
    margin-left: 3%;
}

.bg-home-table {
    width: 50%;
    margin: 0;
}

.message-manage {
    margin: 10%;
    width: 100%;
}


@media (min-width: 0px) and (max-width: 1024px){
    .bg-home-right {
        display: none;
    }

    .bg-home-content {
        flex-wrap: wrap;
    }

    .bg-home-left, .bg-home-table {
        width: 100%;
    }
 
}