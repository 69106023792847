.left-nav-root {

}

.left-nav-header {
    display: flex;
    justify-content: center;
    margin: 30px 0;
}

.mobel-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 80px;
    background-color: #000000;
    border-radius: 10px;
    box-shadow: 2px 2px 2px #888888;
}

.mobel-logo p {
    margin: 0 5px;
}

.logo-left, .logo-right {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 2rem;
    /* height: 60px; */
}

.logo-left {
    color: #ffffff;
}

.logo-right {
    width: 80px;
    height: 40px;
    color: #000000;
    background-color: rgb(255, 153, 0);
    border-radius: 5px;
}

.mobel-logo, .logo-left, .logo-right {
    transition: all 0.5s;
}

@media (min-width: 0px) and (max-width: 650px){

    .mobel-logo {
        width: 100px;
        height: 50px;
        box-shadow: 2px 1px 1px #888888;
    }

    .mobel-logo p {
        margin: 0 3px;
    }

    .logo-left, .logo-right {
        font-size: large;
    }

    .logo-right {
        width: 50px;
        height: 25px;
        border-radius: 2px;
    }

    .mobel-logo, .logo-left, .logo-right {
        transition: all 0.5s;
    }

 } 