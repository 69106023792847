
.home-footer-root {
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #212220;
}


.home-footer-link {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.home-footer-link a {
    margin-top: 8px;
    margin-right: 2vw;
    font-size: x-large;
    filter: brightness(0.6);
    transition: all 0.3s;
}

.home-footer-link a:hover {
    /* font-size: xx-large; */
    transform: scale(1.2, 1.2);
    filter: brightness(1);
    transition: all 0.2s;
}

.home-footer-sitemessage p {
    text-align: center;
    color: #565755;
    width: 100%;
    margin: 0;
    margin-bottom: 5px;
}

.wechat-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 屏幕自适应 */
@media (min-width: 0px) and (max-width: 1024px) {
    .home-footer-link a {
        margin-right: 5vw;
    }
}
