
.labor-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 90vh;
    /* width: 100%;
    margin: 2vh 0;
    background-color: #ffffff;
    filter: opacity(95%);
    animation: about-ami 1s both;
    border-radius: 10px; */
}

.labor-title {
    width: 100%;
    min-width: 350px;
    margin: 2vh 0;
    padding: 20px;
    /* height: 15vh; */
    background-color: #ffffff;
    filter: opacity(95%);
    animation: labor-ami 1s both;
    border-radius: 10px;
}

.labor-title svg {
    position: relative;
    bottom: -4px;
    margin: 0 3px;
}

.labor-title span {
    margin-right: 15px;
    white-space: nowrap;
}

.labor-root h1 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin: 1rem 0;
    padding-left: 1rem;
    border-left: .25rem solid #6bc30d;
}

.labor-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.labor-item-father {
    display: flex;
    justify-content: center;
    animation: labor-ami 1s both;
}

.labor-item {
    width: 350px;
    height: 200px;
    /* background-color: #ffffff; */
    filter: opacity(95%);
    border-radius: 10px;
    margin: 30px 0;
    /* margin-right: 5vw; */
    padding: 25px;
    cursor: pointer;
    position: relative;
    background-color: #fff;
    filter: opacity(0.95);
    box-shadow:
    1.3px 2.8px 2.2px rgba(0, 0, 0, 0.025),
    3.2px 6.7px 5.3px rgba(0, 0, 0, 0.036),
    6px 12.5px 10px rgba(0, 0, 0, 0.044),
    10.7px 22.3px 17.9px rgba(0, 0, 0, 0.051),
    20.1px 41.8px 33.4px rgba(0, 0, 0, 0.058),
    48px 100px 80px rgba(0, 0, 0, 0.07);
    transition: all 0.3s;
}

.labor-item:hover {
    transform: scale(1.02, 1.02);
}

.labor-item-title {
    text-align: center;
    margin: 10px 0;
}

.labor-item-title h2 {
    font-size: 2rem;
}

.labor-item-description {
    filter: opacity(0.7)
}

@keyframes labor-ami
{
    0% {
        opacity: 0;
        transform: scale3d(.3,.3,.3);
    }
    50% {
        opacity: 0.95;
    }
}
