

.article-root {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 2vh;
    /* margin-top: 200vh; */
}

.article-list {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-right: 1vw;
    filter: opacity(90%);
    min-height: 90vh;
    /* width: 50vw;    */
    /* margin-top: 150vh; */
    /* height: 100%; */
    /* background-color: green;  */
}

.article-menu {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; */
    width: 300px;  
    height: 100%;
    /* background-color: #fff; */
    /* filter: opacity(100%); */
}

.article-view {
    /* height: 38vh; */
    width: 100%;
    padding: 2vh 3vw;
    margin-bottom: 2vh;
    background-color: #fff;
    animation:mymove 0.5s ease-out 1;
    -webkit-animation:mymove 0.5s ease-out 1; /*Safari and Chrome*/
    /* box-shadow: 1px -1px 1px #a19c9c; */
    box-shadow:
    0.9px 2px 2.2px rgba(0, 0, 0, 0.006),
    1.9px 4.4px 5.3px rgba(0, 0, 0, 0.01),
    3.2px 7.5px 10px rgba(0, 0, 0, 0.015),
    5.2px 12.3px 17.9px rgba(0, 0, 0, 0.02),
    8.8px 20.8px 33.4px rgba(0, 0, 0, 0.029),
    19px 45px 80px rgba(0, 0, 0, 0.07);   

    border-radius: 10px;
}

.article-zero {
    width: calc(100vw - 20vw - 300px);
    text-align: center;
    margin-top: 30vh;
    font-size: xx-large;
    color: #e8e9e7;
}

.article-view-head {
    display: flex;
    position: relative;
    width: 100%;
    height: 4vh;
}

.article-view-content {
    /* height: 23vh; */
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 15px;
    /* padding-top: 20px; */
}

.article-view-content img {
    /* margin-top: 20px; */
    margin-right: 10px;
    width: 300px;
    height: 180px;
    /* height: 15vh;
    width: 16vw;
    min-width: 220px;
    min-height: 160px; */
    border: 1px solid #e8e9e7;
    float: left;
}

.article-view-content p {
    line-height: 28px;
    /* text-overflow: ellipsis; */
    /* overflow:hidden; */
}

.article-view-footer {
    /* height: 5vh; */
    margin-top: 10px;
    position: relative;
}

.article-view-title {
    width: 85%;
    border-bottom: 1px solid #e8e9e7;
    line-height: 30px;
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.article-view-title a {
    color: #000000;
}

.article-view-title a:hover {
    color: #6bc30d;
}

.article-view-creative {
    color: #2ea7e0;
}

.article-view-time {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    top: -10px;
    right: -40px;
    line-height: 35px;
    width: 20%;
    min-width: 80px;
    white-space: nowrap;
}

.article-view-time .day {
    display: block;
    text-align: center;
    font-weight: 700;
    font-size: 40px;
    color: #6bc30d;
    position: relative;
    width: 100%;
    /* top: 2px; */
}

.article-view-time .month, .article-view-time .year {
    font-size: 16px;
    font-weight: 500;
    color: #989997;
}

.read-more a {
    color: #383937;;
    font-weight: bold;
}

.read-more a:hover {
    color: #6bc30d;
}

.read-more::before {
    content: " ";
    display: inline-block;
    height: 1px;
    top: 10px;
    left: 90px;
    right: 0;
    /* width: 86%; */
    position: absolute;
    background-color: #d0d0d0;
}

.article-message {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.article-tags {
    margin-top: 10px;
}

.article-tags a {
    display: inline-block;
    font-size: 12px;
    padding: 2px 5px;
    background-color: #f1f2f0;
    color: #787977;
    margin-left: 5px;
    text-align: center;
    /* margin: 2px; */
    /* text-decoration: none; */
    -webkit-transition: all .2s;
    transition: all .2s;
    font-style: normal;
}

.article-tags a:hover {
    color: #f1f2f0;
    background-color: #6bc30d;
}

.article-pagination {
    margin: 2vh 0;
}

.article-menu-sc {
    width: 100%;
    padding-bottom: 10px;
    /* height: 30vh; */
    background-color: #ffffff;
    box-shadow: 1px -1px 2px #888888;
    filter: opacity(90%);
    border-radius: 10px;
}

.article-menu-search {
    /* height: 8vh; */
    padding: 20px;
    background-color: grey;
    border-radius: 10px 10px 0 0;
}

.search-input {
    /* height: 100%; */
    width: 100%;
    border-radius: 20px;
}

.search-button {
    position: absolute;
    top: 26px;
    right: 30px;
    transition: all 0.2s;
}

.search-button:hover, .link-message:hover {
    color: #6bc30d;
    cursor: pointer;
    transition: all 0.2s;
}

.category-list {
    margin: 10px 0;
    padding: 0;
}

.category-list li {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 38px;
    /* margin: 0 30px;
    padding: 0 20px; */
    border-bottom: 1px solid #f8f9f7;
    cursor: pointer;
    color: #787977;
    transition: all .3s;
}

.category-list li:hover {
    background-color: rgba(233, 238, 239, 0.6);
    transition: all .1s;
    border-right: 6px solid #484947;
}

.category-list li span {
    padding: 0 40px; 
}

.category-move {
    width: 100%;
    padding: 0;
    background-color: #ffffff;
    box-shadow: 1px -1px 2px #888888;
    position: fixed; 
    top: auto;
    margin-top: 0;
    width: 277px;
    right: calc((100% - 1280px)/2);
    z-index: 100;
    transition: all .3s;
    transform: scale(0);
}


.article-menu-hot {
    margin: 2vh 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 1px -1px 2px #888888;
    filter: opacity(90%);
    border-radius: 10px;
}

.article-menu-hot h5, .article-menu-link h5 {
    margin: 0 10px 10px;
    padding: 10px 5px 5px;
    line-height: 30px;
    font-weight: 400;
    border-bottom: 1px solid #e8e9e7;
    color: #383937;
    position: fixed;
    font-size: 18px;
    right: 0;
    left: 0;
    z-index: 10;
    background-color: white;
}

.hot-article, .friendly-link {
    margin: 0 20px;
    padding: 50px 0 10px;
}

.hot-article ul, .friendly-link ul {
    counter-reset: myCounter;
    padding: 0;
    margin: 5px 0;
}

.hot-article li, .friendly-link li {
    display: flex;
    align-items: center;
    padding-left: 30px;
    counter-increment: myCounter;
    line-height: 32px;
    margin: 3px 0;
    white-space: nowrap;
}

.hot-article li a, .friendly-link li a {
    color: #787977;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    left: -10px;
    width: 100%;
}

.hot-article li a:hover, .friendly-link li a:hover {
    color: #6bc30d;
}

.hot-article li:first-child::before {
    background-color: #e24d46;
}

.hot-article  li:nth-child(2)::before {
    background-color: #2ea7e0;
}

.hot-article  li:nth-child(3)::before {
    background-color: #6bc30d;
}

.hot-article  li:nth-child(-n+3)::before {
    color: white;
}

.hot-article li::before, .friendly-link li::before {
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    content: counter(myCounter, decimal);
    position: relative;
    left: -20px;
    border-radius: 100%;
    background-color: #edefee;
    text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
}

.article-menu-link {
    width: 100%;
    max-height: 30vh;
    background-color: #ffffff;
    box-shadow: 1px -1px 2px #888888;
    overflow: auto;
    filter: opacity(90%);
    border-radius: 10px;
}

.link-message {
    position: relative;
    top: 1px;
    left: 5px;
}

.article-museScore {
    height: 100vh;
}

.article-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60vw;
    height: 90vh;
}

/*滚动条样式*/
.article-menu-link::-webkit-scrollbar {
    width: 5px;    
    /*height: 4px;*/
}
.article-menu-link::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(0,0,0,0.1);
}
.article-menu-link::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 0;
    background: rgba(0,0,0,0.1);
}


@media (min-width: 0px) and (max-width: 1024px) {

    .article-root {
        width: 90vw; 
    }

    .article-menu, .category-move {
        display: none;
    }

    .article-view {
        padding: 1vh 3vw;

    }

    .article-view-content {
        height: 21vh;
    }

    .article-view-content img {
        width: 30vw;
        height: 15vh;
    }

    .article-list {
        width: 100%;   
        min-height: 90vh;
    }
}

@media (min-width: 0px) and (max-width: 480px){
    .article-view-title {
        width: 100%;
    }
}

@media (min-width: 0px) and (max-width: 1024px){
    .article-view-time {
        display: none;
    }
 } 

@media (min-width: 1024px) and (max-width: 1366px){
   .category-move {
       right: calc((100% - 80vw)/2);
   }
} 

@keyframes mymove
{
	from {opacity: 0;transform: scale3d(.3,.3,.3);}
	to {opacity: 1;}
}

@-webkit-keyframes mymove /*Safari and Chrome*/
{
	from {opacity: 0;transform: scale3d(.3,.3,.3);}
	to {opacity: 1;}
}